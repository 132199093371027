import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import { defineSlots, removeSlots } from './Ads.utils';

export default function AdsLoader(): React.ReactElement {
  const [, { language }] = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const updateSlots = () => {
      removeSlots();
      defineSlots(language);
    };

    defineSlots(language);
    router.events.on('routeChangeComplete', updateSlots);
    return () => {
      router.events.off('routeChangeComplete', updateSlots);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Head>
      <Script
        async={true}
        src={`https://www.googletagservices.com/tag/js/gpt.js`}
      />
      <Script>
        {`var googletag = googletag || {};
          googletag.cmd = googletag.cmd || [];`}
      </Script>
    </Head>
  );
}
